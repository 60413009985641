<template>
  <file-pond
    :name="name"
    credits=""
    :class-name="`vms-filepond ${customClassName}`"
    :label-idle="labelIdle"
    :label-tap-to-undo="labelTapToUndo"
    :label-file-processing-error="labelFileProcessingError"
    :max-file-size="maxFileSize"
    :label-file-type-not-allowed="labelFileTypeNotAllowed"
    :file-validate-type-label-expected-types="
      fileValidateTypeLabelExpectedTypes
    "
    :allow-multiple="allowMultiple"
    :chunk-uploads="chunkUploads"
    :chunk-size="chunkSize"
    :chunk-force="chunkForce"
    @addfile="handleAddFile"
    @removefile="handleRemoveFile"
    @processfiles="handleProcessFile"
    @updatefiles="handleUpdateFiles"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import vueFilePond, { setOptions } from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { parseInt } from 'lodash'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

export default {
  components: {
    FilePond,
  },

  props: {
    apiUrl: {
      type: String,
      required: true,
      default: '',
    },

    name: {
      type: String,
      required: false,
      default: 'attachment',
    },

    customClassName: {
      type: String,
      required: false,
      default: '',
    },

    labelIdle: {
      type: String,
      required: false,
      default: `<div class='vd-dark-grey vms-uploader__text'>Drop your file here or click to <u class='vd-light-blue'>browse</u></div><div class='vd-grey'>Supports videos, images, documents and more! Maximum file size 2GB.</div>`,
    },

    labelTapToUndo: {
      type: String,
      required: false,
      default: 'Tap to clear',
    },

    labelFileProcessingError: {
      type: String,
      required: false,
      default: 'Upload error, file may already exist',
    },

    maxFileSize: {
      type: String,
      required: false,
      default: '2000MB',
    },

    labelFileTypeNotAllowed: {
      type: String,
      required: false,
      default: 'Upload error',
    },

    fileValidateTypeLabelExpectedTypes: {
      type: String,
      required: false,
      default: 'Invalid file type',
    },

    allowMultiple: {
      type: Boolean,
      required: false,
      default: true,
    },

    chunkUploads: {
      type: Boolean,
      required: false,
      default: true,
    },

    chunkSize: {
      type: Number,
      required: false,
      default: 8000000,
    },

    chunkForce: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      authToken: 'auth/accessToken',
    }),

    filePondServerConfig() {
      return {
        url: this.apiUrl,
        process: {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        },
        patch: {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        },
        revert: {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        },
      }
    },
  },

  mounted() {
    setOptions({
      server: this.filePondServerConfig,
    })
  },

  methods: {
    /**
     * This will be called when all the files have finished processing.
     */
    handleProcessFile() {
      this.$emit('handle:process-file')

      // By default, we will update the current page to page 1 when adding new files.
      if (this.$route.query.page && parseInt(this.$route.query.page) > 1) {
        this.$router.replace({
          query: {
            page: 1,
          },
        })
      }
    },

    /**
     * This will be called when a file is added.
     *
     * @param error
     * @param file
     */
    handleAddFile(error, file) {
      this.$emit('handle:add-file', error, file)
    },

    /**
     * This will be called when a file upload is cancelled/removed.
     */
    handleRemoveFile() {
      this.$emit('handle:remove-file')
    },

    /**
     * This will be called when there is change on the files being processed.
     * (e.g file being cancelled)
     *
     * @param files
     */
    handleUpdateFiles(files) {
      this.$emit('handle:update-file', files)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/uploader';
@import '@styles/plugins/vue-filepond';
</style>
